<template>
    <div class="modal-dialog modal-default" role="document">
        <div class="modal-content">
            <b-overlay :show="loading">
                <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Upload Your CSV/Excel File</h5>
                </div>
                <div class="modal-body">
                    <form>
                        <!-- <div class="form-group">
                            <label for="name">Merchant</label>
                             <v-select name="merchant_id"
                                v-model="bulk_upload.merchant_id"
                                label="text"
                                :reduce="item => item.id"
                                :options= merchantList
                                :placeholder="$t('globalTrans.select')"
                            />
                            <span class="text-danger" v-if="errors.merchant_id">{{ errors.merchant_id[0] }}</span>
                        </div> -->
                        <div class="form-group">
                            <label for="file">Upload File</label>
                            <input type="file" @change="onFileChange" class="form-control" accept="xlsx/*" />
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <a :href="excelFormatUrl" target="_blank" class="btn btn-info btn-sm">Download Sample Format</a>
                    <button type="button" class="btn btn-success btn-sm" @click="bulkUpload">Upload</button>
                    <button type="button" class="btn btn-danger btn-sm" @click="bulkModal.hide()">Cancel</button>
                </div>
            </b-overlay>
        </div>
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name: 'BulkUpload',
        props: ['bulkModal','merchantList'],
        data() {
            return {
                excelFormatUrl: this.$baseUrl +'excel-download/bulk-format',
                loading: false,
                bulk_upload: {
                    // merchant_id: '',
                    excel_file: ''
                },
                errors: []
            }
        },
        computed: {
            commonObj () {
                return this.$store.state.commonObj
            }
        },
        methods: {
            onFileChange(e){
                this.bulk_upload.excel_file = e.target.files[0];
            },
            async bulkUpload () {
                this.loading = true
                this.$store.dispatch('stateReload', true)
                let formData = new FormData();
                // formData.append('merchant_id', this.bulk_upload.merchant_id);
                formData.append('excel_file', this.bulk_upload.excel_file);
                const response = await config.postData('/order/bulk-upload', formData)
                this.loading = false  
                if (response.status == 201) {
                    this.bulkModal.hide()
                    // this.bulk_upload.merchant_id = 0
                    this.bulk_upload.excel_file = ''
                    this.$toast.success({
                        title: 'Success',
                        message: 'Bulk order uploaded successfully',
                        color: '#218838'
                    }) 
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })
                }                  
                this.$store.dispatch('stateReload', false)
            }
        }
    }
</script>
<style scoped>
    span.select2-container {
        z-index:10050;
    }
</style>
